var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "BaseUploadedItem fs-14",
      attrs: { tabindex: "0" },
      on: {
        click: _vm.viewDocument,
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.viewDocument($event)
        }
      }
    },
    [
      _c("span", { staticClass: "BaseUploadedItem__text fs-14" }, [
        _vm._v(_vm._s(_vm.index + 1) + ". " + _vm._s(_vm.filename))
      ]),
      _c("i", {
        staticClass: "fa fa-trash-can fa--none fa-15",
        attrs: { tabindex: "0" },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$emit("remove-upload", _vm.index)
          },
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.stopPropagation()
            return _vm.$emit("remove-upload", _vm.index)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }