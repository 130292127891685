<template>
  <li
    @click="viewDocument"
    @keydown.enter="viewDocument"
    class="BaseUploadedItem fs-14"
    tabindex="0"
  >
    <span class="BaseUploadedItem__text fs-14">{{ index + 1 }}. {{ filename }}</span>
    <i
      @click.stop="$emit('remove-upload', index)"
      @keydown.enter.stop="$emit('remove-upload', index)"
      class="fa fa-trash-can fa--none fa-15"
      tabindex="0"
    />
  </li>
</template>

<script>

export default {
  name: 'BaseUploadItem',

  props: {
    filename: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },

  methods: {
    viewDocument () {
      window.open(this.url, '_blank')
    },
  },
}
</script>

<style lang="sass">
.BaseUploadedItem
  +flex($align: center, $justify: space-between)
  cursor: pointer
  width: 100%

  &:hover
    opacity: 0.75

  .fa-trash-can
    padding: rem(10px)

    &:hover
      color: $danger

  &__text
    +overflow-ellipsis()
    padding-right: 1rem
    width: rem(calc(100% - 35px))

</style>
